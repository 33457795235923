import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Profil, UpdateProfil } from './profil';
import { QueryParams } from '@core/shared/util';
import { Tag } from '@core/shared/data-access';

@Injectable({ providedIn: 'root' })
export class ProfilService {

  private readonly baseApiPath = 'api/me';

  constructor(private readonly http: HttpClient) { }

  getProfil(): Observable<Profil> {
    return this.http.get<Profil>(
      `${this.baseApiPath}`,
      {
        // TODO: This does NOT work currently!
        params: QueryParams.build<Profil>()
          .include(['geschaeftspartnerart'] as any)
          .toHttpParams()
      }
    );
  }

  update(updateDto: UpdateProfil): Observable<Profil> {
    return this.http.put<Profil>(
      `${this.baseApiPath}`,
      updateDto
    );
  }

  switchOrganisation(organisationId: number): Observable<Tag> {
    return this.http.put<Tag>(
      `${this.baseApiPath}`,
      { activeOrganisation: organisationId }
    );
  }

  changePassword(passwort: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseApiPath}/reset`, { passwort });
  }
}
